import React, {useState} from 'react'
import { Link } from 'gatsby'
import * as buttonStyle from "../css/btn.module.scss"
import * as formStyle from "../css/form.module.scss"
import axios from "axios"
import Seo from '../components/layout/Seo'
import Layout from '../components/layout/Layout'
import PageHeader from '../components/PageHeader'
import AcceptBox from '../components/AcceptBox'
import SplitBackground from '../svg/SplitBG'


const ContactPage = () => {

const [serverState, setServerState] = useState({
    submitting: false,
    status: null
  });
  const handleServerResponse = (ok, msg, form) => {
    setServerState({
      submitting: false,
      status: { ok, msg }
    });
    if (ok) {
      form.reset();
    }
  };

  const hohnEndpoint = "https://media.fairfld.com/wp-json/contact-form-7/v1/contact-forms/10/feedback";

  const handleOnSubmit = e => {
    e.preventDefault();
    const form = e.target;
    setServerState({ submitting: true });
    axios({
      method: "post",
      url: hohnEndpoint,
      data: new FormData(form)
    })
      .then(r => {
        handleServerResponse(true, "Thanks! We'll be in touch soon.", form);
      })
      .catch(r => {
        handleServerResponse(false, r.response.data.error, form);
      });
  };


  return (
    <Layout>
      <Seo title="Contact" description={`Would you like to get in touch with Höhn? Send him a message.`} />
      <div className="container px-8 md:pb-24 pb-12 mx-auto">
      <div className="bg-transparent block max-w-xl mx-auto" style={{minHeight: "80vh"}}>

      <PageHeader pageTitle="Contact Höhn" pageSubtitle="Use the form below to send a message." align="center" />

    <form className={`form ${formStyle.contactPageForm}`} onSubmit={handleOnSubmit}>
    <div className="flex flex-row flex-wrap md:flex-nowrap">
      <div className="flex-auto w-full sm:w-2/4 pr-0 sm:pr-2 md:pr-3 lg:pr-4 mb-3">
      <label>
        <span className="text-sm 2xl:text-base font-medium text-black block">First Name</span>
        <input type="text" name="firstName" className={`bg-transparent font-light border-2 border-black w-full p-2 h-12 text-base 2xl:text-lg ${formStyle.formField}`} />
      </label>
      </div>
      <div className="flex-auto w-full sm:w-2/4 pl-0 sm:pl-2  md:pl-3 lg:pl-4 mb-3">
        <span className="text-sm 2xl:text-base font-medium text-black block">Last Name</span>
        <input type="text" name="lastName" className={`bg-transparent font-light border-2 border-black w-full p-2 h-12 text-base 2xl:text-lg ${formStyle.formField}`} />
      </div>
    </div>

    <div className="flex flex-row flex-wrap md:flex-nowrap">
      <div className="flex-auto w-full sm:w-3/5 pr-0 sm:pr-2 md:pr-3 lg:pr-4 mb-3">
      <label>
        <span className="text-sm 2xl:text-base font-medium text-black block">Email</span>
        <input type="email" name="email" className={`bg-transparent font-light border-2 border-black w-full p-2 h-12 text-base 2xl:text-lg ${formStyle.formField}`} />
      </label>
      </div>
      <div className="flex-auto w-full sm:w-2/5 pl-0 sm:pl-2  md:pl-3 lg:pl-4 mb-3">
      <label>
        <span className="text-sm 2xl:text-base font-medium text-black block">Subject</span>
        <select name="subject" id="subject" className={`bg-transparent font-light border-2 border-black w-full p-2 h-12 text-base 2xl:text-lg ${formStyle.formField}`}>
          <option name="general" value={`General inquiry`}>General inquiry</option>
          <option name="request-quote" value={`Question for mgmt.`}>Question for mgmt.</option>
          <option name="say-hello" value={`Just to say hello`}>Just to say hello</option>
          <option name="other" value={`Other`}>Other</option>
        </select>
      </label>
      </div>
    </div>
    <div className="flex flex-row flex-wrap md:flex-nowrap mb-3">
      <div className="flex-1 mt-2">
      <label htmlFor="message" className="hidden"><span className="text-sm 2xl:text-base font-medium text-black block">Write your message here...</span></label>
        <textarea id="message" name="message" rows="12" className={`w-full text-base 2xl:text-lg text-black bg-transparent border-2 border-black resize-none p-3 font-light ${formStyle.messageField} `} placeholder="Write your message here..." />
      </div>
    </div>

    <input type="text" name="website_url" style={{visibility: 'hidden', opacity: '0', position: 'absolute', top: '0', left: '0', zIndex: '-1'}} />

		<AcceptBox name={`acceptance`}>
		By leaving this box checked, you are accepting the terms detailed in our <Link className="underline" to={`/privacy`}>Privacy Policy</Link>.
		</AcceptBox>

    <div className="flex flex-row">
    <div className={`flex-1 p-0 ${buttonStyle.btn} `}>
    <input type="submit" className={`w-full h-10 relative text-base font-sans font-light lowercase bg-transparent cursor-pointer`} value="Send Message" disabled={serverState.submitting} />
    </div>
    </div>
    {serverState.status && (
      <div className="pt-4 text-center text-sm 2xl:text-base">
              <span className={`${!serverState.status.ok ? "errorMsg" : ""} font-medium tracking-normal`}>
              {serverState.status.msg}
              </span>
      </div>
          )}
    </form>


      </div>
      </div>
      <SplitBackground isReversed />
    </Layout>
  )

}

export default ContactPage
