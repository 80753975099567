import React from "react"
import * as bgStyle from "../css/backgrounds.module.scss"


const SplitBackground = ( {isReversed, ...rest} ) => {

	if (isReversed) {
		return (
			<div className={`bg-custom-overlay ${bgStyle.bgOverlay} ${bgStyle.bgReversed}`} {...rest} />
		)
	}

	return (
		<div className={`bg-custom-overlay ${bgStyle.bgOverlay} `} {...rest} />
	)
}

export default SplitBackground
